body {
  margin:  0;
  background: #2e3440;
  text-align: center;
}

.board {
  background: #4c566a;
  color: #eceff4;
  border: 4px solid #434c5e;
  border-radius: 15px;
  box-shadow: 0 8px 16px 0 rgba(0,0,0,0.4);
  display: inline-block;
  margin: 50px;
  padding: 30px;
  text-align: center;
}

.status {
  font: 18pt sans-serif;
  line-height: 20pt;
}

.square-grid {
  background: #2e3440;
  display: grid;
  grid-gap: 2px;
  grid-template-columns: repeat(3, 50px);
  grid-template-rows: repeat(3, 50px);
  line-height: 50px;
  margin: 30px 15px;
  width: 150px;
}

.square {
  background: #4c566a;
  cursor: default;
  font: bold 24pt sans-serif;
  line-height: inherit;
}

.reset-button {
  background: #434c5e;
  border: 2px solid #3b4252;
  border-radius: 5px;
  color: #e5e9f0;
  font: 16pt sans-serif;
  height: 40px;
  width: 100px;
}
